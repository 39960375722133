<template>
  <div>
    <div class="u-bottom-margin--5x">
      <div class="u-bottom-padding--0 u-font--bold u-font--xlarge">
        {{ $t("sendNotificationsPageLabel") }}
      </div>
      <div class="u-font--small u-color--grey-light">
        {{ $t("sendNotificationsPageSubLabel") }}
      </div>
    </div>
    <el-form
      ref="notificationForm"
      :model="form"
      :rules="rules"
      label-width="170px"
      @submit.native.prevent="submitForm('notificationForm')"
    >
      <el-form-item prop="selectedAreas">
        <MetricFilterBox
          :defaultFilterData="metrics.states"
          v-model="metricsList"
          @openMetric="saveMetircs"
        >
          <metricbox
            v-for="metric in metricsList"
            :key="metric.key"
            :label="$t(metric.label)"
            @close="closeMetric(metric)"
          >
            <template slot="filterBody">
              <component
                v-if="metric.key != 'selectedAreas'"
                :is="metric.compName"
                v-model="form.userGroupFilters[metric.key]"
                @change="checkValidation"
              ></component>

              <component
                v-else
                :is="metric.compName"
                v-model="form[metric.key]"
                @change="checkValidation"
              ></component>
            </template>
          </metricbox>
        </MetricFilterBox>
      </el-form-item>

      <el-form-item prop="">
        <input
          type="file"
          ref="file"
          @change="handleClick"
          accept=".xlsx,.csv"
        />
      </el-form-item>

      <el-form-item
        :label="$t('sendNotificationsPageFieldNotificationTitle')"
        prop="notificationTitle"
      >
        <div style="position: relative">
          <picker
            :native="true"
            :showCategories="true"
            :showPreview="true"
            :style="{
              position: 'absolute',
              left: ' 20%',
              'z-index': '99999',
              display: pickerShown ? 'flex' : 'none',
            }"
            @select="addEmoji($event, 'markdownEditorTitle')"
          />

          <Editor
            @load="onEditorLoad($event, 'markdownEditorTitle')"
            ref="markdownEditorTitle"
            initialEditType="wysiwyg"
            height="130px"
            :options="editorOptions"
          />
        </div>
      </el-form-item>

      <el-form-item
        :label="$t('sendNotificationsPageFieldNotificationMessage')"
        prop="notificationBody"
      >
        <div style="position: relative">
          <picker
            :native="true"
            :showCategories="true"
            :showPreview="true"
            :style="{
              position: 'absolute',
              left: ' 20%',
              'z-index': '99999',
              display: picker2Shown ? 'flex' : 'none',
            }"
            @select="addEmoji($event, 'markdownEditorBody')"
          />

          <Editor
            @load="onEditorLoad($event, 'markdownEditorBody')"
            ref="markdownEditorBody"
            height="250px"
            initialEditType="wysiwyg"
            :options="editorOptions"
          />
        </div>
      </el-form-item>

      <el-form-item>
        <el-button :loading="sendingNotification" native-type="submit">
          {{ $t("sendNotificationsPageButtonLabelSendNotification") }}
        </el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      :title="$t('sendNotificationsPageDialogNotFoundTitle')"
      :visible.sync="notFoundDialogIsVisibile"
    >
      <div>
        <div v-for="(item, index) in notFoundUsersArray" :key="index">
          <span>- </span>
          <span>{{ item }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="closeNotFoundUsersDialog">{{
          $t("sendNotificationsPageDialogNotFoundButtonLabelOk")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { adminActions } from "@/services";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import metricbox from "@/components/transaction/metricbox";
import { notificationMetrics } from "../components/transaction/metricboxComponents";
import { Picker } from "emoji-mart-vue";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
import { mapGetters } from "vuex";
function createLastButton(handler) {
  const button = document.createElement("button");
  button.className = "toastui-editor-toolbar-icons last";
  button.style.backgroundImage = "none";
  button.style.margin = "0";
  button.innerHTML = `<i class="el-icon-bicycle"></i>`;
  button.addEventListener("click", (e) => {
    handler();
    e.preventDefault();
  });
  return button;
}
export default {
  name: "NotificationsPage",
  components: {
    MetricFilterBox,
    ...notificationMetrics,
    metricbox,
    Editor,
    Picker,
  },
  data() {
    var validateAreaOrRegion = (rule, value, callback) => {
      if (this.checkUserGroupFilters && this.form.selectedAreas.length < 1) {
        callback(
          new Error(
            this.$t("sendNotificationsPageValidationAreaOrRegionErrorMessage")
          )
        );
      } else {
        callback();
      }
    };

    return {
      metrics: {
        states: [
          {
            key: "selectedAreas",
            label: "sendNotificationsPageMetricLabelAreas",
            compName: "areaMetric",
          },
          {
            key: "regions",
            label: "sendNotificationsPageMetricLabelRegions",
            compName: "regionMetric",
          },
          {
            key: "providers",
            label: "sendNotificationsPageMetricLabelProviders",
            compName: "providersMetric",
          },
          {
            key: "T_MAX",
            label: "sendNotificationsPageMetricLabelFromT",
            compName: "inputMetric",
          },
          {
            key: "T_MIN",
            label: "sendNotificationsPageMetricLabelToT",
            compName: "inputMetric",
          },
          /////////////////
          //sum Transaction
          /////////////////
          {
            key: "minSumTrans",
            label: "sendNotificationsPageMetricLabelSumFrom",
            compName: "inputMetric",
          },
          {
            key: "maxSumTrans",
            label: "sendNotificationsPageMetricLabelSumTo",
            compName: "inputMetric",
          },
          {
            key: "sumPeriod",
            label: "sendNotificationsPageMetricLabelSumPeriod",
            compName: "periodMetric",
          },

          {
            key: "sumStartDate",
            label: "sendNotificationsPageMetricLabelSumStartDate",
            compName: "startDateMetric",
          },
          {
            key: "sumStartDate",
            label: "sendNotificationsPageMetricLabelSumEndDate",
            compName: "startDateMetric",
          },
          /////////////////
          //Avrage Transaction amount
          /////////////////

          {
            key: "minAvgTrans",
            label: "sendNotificationsPageMetricLabelSumEndDateAvgFrom",
            compName: "inputMetric",
          },
          {
            key: "maxAvgTrans",
            label: "sendNotificationsPageMetricLabelAvgPeriod",
            compName: "inputMetric",
          },
          {
            key: "avgPeriod",
            label: "sendNotificationsPageMetricLabelAvgPeriodP",
            compName: "periodMetric",
          },

          {
            key: "avgStartDate",
            label: "sendNotificationsPageMetricLabelAvgStartDate",
            compName: "startDateMetric",
          },
          {
            key: "avgEndDate",
            label: "sendNotificationsPageMetricLabelAvgEndDate",
            compName: "startDateMetric",
          },
          /////////////////
          //Avrage Transaction amount
          /////////////////

          {
            key: "minNumOfTrans",
            label: "sendNotificationsPageMetricLabelNumTrxFrom",
            compName: "inputMetric",
          },
          {
            key: "maxNumOfTrans",
            label: "sendNotificationsPageMetricLabelNumTrxTo",
            compName: "inputMetric",
          },
          {
            key: "numTransPeriod",
            label: "sendNotificationsPageMetricLabelNumTrxPeriod",
            compName: "periodMetric",
          },

          {
            key: "numTransStartDate",
            label: "sendNotificationsPageMetricLabelNumTrxStartDate",
            compName: "startDateMetric",
          },
          {
            key: "numTransEndDate",
            label: "sendNotificationsPageMetricLabelNumTrxEndDate",
            compName: "startDateMetric",
          },

          ///

          {
            key: "userStartDate",
            label: "sendNotificationsPageMetricLabelUserStartDate",
            compName: "startDateMetric",
          },
          {
            key: "userEndDate",
            label: "sendNotificationsPageMetricLabelEndDate",
            compName: "startDateMetric",
          },
        ],
      },
      metricsList: [],

      editorOptions: {
        minHeight: "200px",
        useDefaultHTMLSanitizer: true,
        useCommandShortcut: false,
        hideModeSwitch: true,
        toolbarItems: [["bold", "italic", "strike", "heading"]],
      },
      pickerShown: false,
      picker2Shown: false,
      isAllArea: false,
      sendingNotification: false,
      form: {
        notificationBody: "",
        notificationTitle: "",
        selectedAreas: [],
        userGroupFilters: {
          regions: [],
          T_MAX: "",
          T_MIN: "",
          userStartDate: "",
          providers: [],
          userEndDate: "",
        },
      },
      rules: {
        notificationBody: [
          {
            required: true,
            message: this.$t(
              "sendNotificationsPageValidationRuleNotificationBody"
            ),
            trigger: "change",
          },
        ],
        notificationTitle: [
          {
            required: true,
            message: this.$t(
              "sendNotificationsPageValidationRuleNotificationTitle"
            ),
            trigger: "change",
          },
        ],
        selectedAreas: [{ validator: validateAreaOrRegion, trigger: "blur" }],
      },
      excelSheet: null,
      notFoundUsersArray: [],
      notFoundDialogIsVisibile: false,
    };
  },
  watch: {
    lookups: function () {
      this.$loading().close();
    },
  },
  mounted() {
    if (!this.lookups) this.$loading();
    let metrics = localStorage.getItem("notifiactionMetrics");
    if (metrics) this.metricsList = JSON.parse(metrics);
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
      user: "Authentication.user",
    }),
    checkUserGroupFilters() {
      const userGroupsFilter = this.form.userGroupFilters;
      for (const key in userGroupsFilter) {
        const element = userGroupsFilter[key];
        if (!_.isEmpty(element)) return false;
      }
      return true;
    },
  },
  methods: {
    checkValidation() {
      if (this.form.notificationBody && this.form.notificationTitle) {
        this.$refs.notificationForm.validate();
      }
    },
    // *********************************************************
    // metric box Area
    // *********************************************************

    closeMetric(metric) {
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      // to make reactivity work as loadash's remove has a problem with vue
      this.metricsList = [...this.metricsList];
      this.form.userGroupFilters[metric.key] = undefined;
      this.saveMetircs();
      this.checkValidation();
    },
    saveMetircs() {
      localStorage.setItem(
        "notifiactionMetrics",
        JSON.stringify(this.metricsList)
      );
    },
    // *********************************************************
    // Edtior Area
    // *********************************************************

    onEditorLoad(editor, target) {
      // To fix a bug in the editor load event called when editor  not fully loaded
      setTimeout(() => {
        editor.insertToolbarItem(
          { groupIndex: 0, itemIndex: 4 },
          {
            el: createLastButton(() => {
              if (target == "markdownEditorTitle") {
                this.pickerShown = !this.pickerShown;
                return;
              }
              this.picker2Shown = !this.picker2Shown;
            }),
            tooltip: "Emoji",
            style: "color:black;",
          }
        );
      }, 0);
    },

    addEmoji(emoji, target) {
      this.$refs[target].invoke("insertText", emoji.native);
      if (target == "markdownEditorTitle") {
        this.pickerShown = false;
        return;
      }
      this.picker2Shown = false;
    },
    // *********************************************************
    // Form  Area
    // *********************************************************
    submitForm(formName) {
      this.form.notificationBody =
        this.$refs.markdownEditorBody.invoke("getMarkdown");
      this.form.notificationTitle =
        this.$refs.markdownEditorTitle.invoke("getMarkdown");
      if (this.excelSheet) {
        this.confirmSendNotification();
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.confirmSendNotification();
          } else {
            return false;
          }
        });
      }
    },

    confirmSendNotification() {
      this.$confirm(
        this.$t("sendNotificationsPagePopupMessage"),
        this.$t("sendNotificationsPagePopupTitle"),
        {
          confirmButtonText: this.$t("sendNotificationsPagePopupButtonSend"),
          cancelButtonText: this.$t("sendNotificationsPagePopupButtonCancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.sendNotification();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("sendNotificationsPagePopupCatchErrorMessage"),
          });
        });
    },
    sendNotification() {
      this.sendingNotification = true;
      this.$loading();
      if (this.excelSheet) {
        var form = new FormData();
        form.append("file", this.excelSheet);
        form.append("message", this.form.notificationBody);
        form.append("title", this.form.notificationTitle);
        adminActions
          .sendBulkNotification(form)
          .then(
            (res) => {
              let responseData = res.data;
              // const successMessage = `${responseData.recipients} out of ${responseData.total} user ids found successfully`;
              const successMessage =
                responseData.recipients +
                " " +
                this.$t("sendNotificationsPageSendResponseOutOf") +
                " " +
                responseData.total +
                " " +
                this.$t("sendNotificationsPageSendResponseUserIds");
              this.$message({
                message: successMessage,
                type: "success",
                duration: 5000,
              });
              setTimeout(() => {
                if (
                  responseData.notFoundUsers &&
                  responseData.notFoundUsers.length > 0
                ) {
                  this.notFoundUsersArray = responseData.notFoundUsers;
                  this.notFoundDialogIsVisibile = true;
                }
              }, 3000);
              this.$refs["file"].value = "";
              this.excelSheet = null;
            }
            // ,
            // () => {
            //   this.$message.error("Oops, sending notifications failed");
            // }
          )
          .catch((e) => {
            console.log("error", e);
            this.$message({
              message: this.$t("sendNotificationsPageSendCatchErrorMessage"),
              type: "error",
            });
          })
          .finally(() => {
            this.sendingNotification = false;
            this.$loading().close();
          });
      } else {
        adminActions
          .sendNotification({
            message: this.form.notificationBody,
            userGroupFilters: this.form.userGroupFilters,
            title: this.form.notificationTitle,
            areas: this.form.selectedAreas,
          })
          .then(
            (res) => {
              let statistics = res.data.response[0];
              const successMessage = `Notification sent successfully to ${statistics.success} users
                , and failed for ${statistics.failure}`;
              this.$message({
                message: successMessage,
                type: "success",
              });
            },
            () => {
              this.$message.error(
                this.$t("sendNotificationsPageSendCatchErrorMessage2")
              );
            }
          )
          .finally(() => {
            this.sendingNotification = false;
            this.$loading().close();
          });
      }
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      if (/\.(csv|xlsx)$/.test(rawFile.name)) {
        this.excelSheet = rawFile;
      } else {
        this.$message({
          message: this.$t("sendNotificationsPageFileValidationErrorMessage"),
          type: "error",
        });
        this.excelSheet = null;
      }
    },
    closeNotFoundUsersDialog() {
      this.notFoundDialogIsVisibile = false;
      this.notFoundUsersArray = [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
