var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"u-bottom-margin--5x"},[_c('div',{staticClass:"u-bottom-padding--0 u-font--bold u-font--xlarge"},[_vm._v(" "+_vm._s(_vm.$t("sendNotificationsPageLabel"))+" ")]),_c('div',{staticClass:"u-font--small u-color--grey-light"},[_vm._v(" "+_vm._s(_vm.$t("sendNotificationsPageSubLabel"))+" ")])]),_c('el-form',{ref:"notificationForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"170px"},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.submitForm('notificationForm')}}},[_c('el-form-item',{attrs:{"prop":"selectedAreas"}},[_c('MetricFilterBox',{attrs:{"defaultFilterData":_vm.metrics.states},on:{"openMetric":_vm.saveMetircs},model:{value:(_vm.metricsList),callback:function ($$v) {_vm.metricsList=$$v},expression:"metricsList"}},_vm._l((_vm.metricsList),function(metric){return _c('metricbox',{key:metric.key,attrs:{"label":_vm.$t(metric.label)},on:{"close":function($event){return _vm.closeMetric(metric)}}},[_c('template',{slot:"filterBody"},[(metric.key != 'selectedAreas')?_c(metric.compName,{tag:"component",on:{"change":_vm.checkValidation},model:{value:(_vm.form.userGroupFilters[metric.key]),callback:function ($$v) {_vm.$set(_vm.form.userGroupFilters, metric.key, $$v)},expression:"form.userGroupFilters[metric.key]"}}):_c(metric.compName,{tag:"component",on:{"change":_vm.checkValidation},model:{value:(_vm.form[metric.key]),callback:function ($$v) {_vm.$set(_vm.form, metric.key, $$v)},expression:"form[metric.key]"}})],1)],2)}),1)],1),_c('el-form-item',{attrs:{"prop":""}},[_c('input',{ref:"file",attrs:{"type":"file","accept":".xlsx,.csv"},on:{"change":_vm.handleClick}})]),_c('el-form-item',{attrs:{"label":_vm.$t('sendNotificationsPageFieldNotificationTitle'),"prop":"notificationTitle"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('picker',{style:({
            position: 'absolute',
            left: ' 20%',
            'z-index': '99999',
            display: _vm.pickerShown ? 'flex' : 'none',
          }),attrs:{"native":true,"showCategories":true,"showPreview":true},on:{"select":function($event){return _vm.addEmoji($event, 'markdownEditorTitle')}}}),_c('Editor',{ref:"markdownEditorTitle",attrs:{"initialEditType":"wysiwyg","height":"130px","options":_vm.editorOptions},on:{"load":function($event){return _vm.onEditorLoad($event, 'markdownEditorTitle')}}})],1)]),_c('el-form-item',{attrs:{"label":_vm.$t('sendNotificationsPageFieldNotificationMessage'),"prop":"notificationBody"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('picker',{style:({
            position: 'absolute',
            left: ' 20%',
            'z-index': '99999',
            display: _vm.picker2Shown ? 'flex' : 'none',
          }),attrs:{"native":true,"showCategories":true,"showPreview":true},on:{"select":function($event){return _vm.addEmoji($event, 'markdownEditorBody')}}}),_c('Editor',{ref:"markdownEditorBody",attrs:{"height":"250px","initialEditType":"wysiwyg","options":_vm.editorOptions},on:{"load":function($event){return _vm.onEditorLoad($event, 'markdownEditorBody')}}})],1)]),_c('el-form-item',[_c('el-button',{attrs:{"loading":_vm.sendingNotification,"native-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("sendNotificationsPageButtonLabelSendNotification"))+" ")])],1)],1),_c('el-dialog',{attrs:{"title":_vm.$t('sendNotificationsPageDialogNotFoundTitle'),"visible":_vm.notFoundDialogIsVisibile},on:{"update:visible":function($event){_vm.notFoundDialogIsVisibile=$event}}},[_c('div',_vm._l((_vm.notFoundUsersArray),function(item,index){return _c('div',{key:index},[_c('span',[_vm._v("- ")]),_c('span',[_vm._v(_vm._s(item))])])}),0),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.closeNotFoundUsersDialog}},[_vm._v(_vm._s(_vm.$t("sendNotificationsPageDialogNotFoundButtonLabelOk")))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }